<template>
    <div>
        <template v-if="isConfirm">
            <h2>
                <div class="mb-4 text-primary">
                    <font-awesome-icon
                        icon="envelope-open-text"
                        transform="grow-16" />
                </div>
                E-mail confirmation
            </h2>
            <p>Your account has been successfully created.</p>
            <p class="text-left">
                An email has been sent to
                <span class="control-label text-primary">sample@domain.com</span
                >. Please check your inbox and click the link in the email to
                activate your new account.
            </p>
        </template>
        <template v-else>
            <h2>Sign up</h2>
            <p>
                Already have an account?
                <b-link to="login" v-show="$route.name != 'login'"
                    >Log in</b-link
                >
            </p>
            <b-form @submit.prevent="" novalidate>
                <b-form-group>
                    <label for="email-field" class="control-label text-primary"
                        >Email address</label
                    >
                    <span
                        v-show="isInvalidDirty('email')"
                        class="invalid-feedback"
                        >Please enter a valid email</span
                    >
                    <b-form-input
                        id="email-field"
                        type="email"
                        required
                        autocapitalize="none"
                        autocorrect="off"
                        spellcheck="false"
                        autocomplete="email"
                        v-validate="'required|email'"
                        v-model="form.email"
                        name="email"
                        :class="{ 'is-invalid': isInvalidDirty('email') }">
                    </b-form-input>
                </b-form-group>

                <b-form-group>
                    <label
                        for="username-field"
                        class="control-label text-primary"
                        >Username</label
                    >
                    <span
                        v-show="isInvalidDirty('username')"
                        class="invalid-feedback"
                        >Please enter a valid username</span
                    >
                    <b-form-input
                        id="username-field"
                        type="text"
                        required
                        autocapitalize="none"
                        autocorrect="off"
                        spellcheck="false"
                        autocomplete="username"
                        v-validate="{ required: true, regex: usrRegex }"
                        v-model="form.username"
                        name="username"
                        :class="{ 'is-invalid': isInvalidDirty('username') }">
                    </b-form-input>
                </b-form-group>

                <label for="password-field" class="control-label text-primary"
                    >New password</label
                >
                <span
                    v-show="isInvalidDirty('password')"
                    class="invalid-feedback">
                    {{
                        form.password.length
                            ? `Please use ${pwdMinLength} characters or more`
                            : 'Please enter a valid password'
                    }}
                </span>
                <password
                    ref="passwordField"
                    id="password-field"
                    placeholder=""
                    required
                    :toggle="true"
                    :badge="false"
                    :default-class="'form-control'"
                    @feedback="getPwdFeedback"
                    @focusin.native="setPwdFocusClass"
                    @focusout.native="setPwdFocusClass(false)"
                    v-validate="{ required: true, min: pwdMinLength }"
                    v-model="form.password"
                    name="password"
                    :class="{ 'is-invalid': isInvalidDirty('password') }" />

                <b-form-group>
                    <b-form-checkbox v-model="form.isAgreed">
                        I have read and agree to the
                        <b-link target="_blank" :href="tclink"
                            >Terms of Use</b-link
                        >, including the limited processing of personal data.
                    </b-form-checkbox>
                </b-form-group>
                <b-button
                    class="signup-btn w-100"
                    type="submit"
                    variant="primary"
                    :disabled="loading || !form.isAgreed">
                    <span v-show="!loading">Sign up</span>
                    <span class="loading" v-show="loading"></span>
                </b-button>
            </b-form>
        </template>
    </div>
</template>

<script>
import Password from 'vue-password-strength-meter';

export default {
    name: 'Registration',
    components: { Password },

    props: {
        usrRegex: {
            type: RegExp,
            default: function () {
                return /^[_.@A-Za-z0-9-]*$/;
            },
        },

        pwdMinLength: {
            type: Number,
            default: parseInt(process.env.VUE_APP_PWD_MIN_LENGTH),
        },

        tclink: {
            type: String,
            default: process.env.VUE_APP_TERMS_URL,
        },
    },

    data() {
        return {
            loading: false,
            isConfirm: true,
            form: {
                username: '',
                email: '',
                password: '',
                isAgreed: false,
            },
            pwdFeedback: {
                suggestions: '',
                warnings: '',
            },
        };
    },

    methods: {
        /**
         * Flags a given field if it has an error and has been changed at least once.
         * @param {string} fieldName - Value of the HTML "name" attribute of the field in question.
         */
        isInvalidDirty(fieldName) {
            // const field = this.veeFields[fieldName];
            // return field && field.invalid && field.dirty;
            return false;
        },

        getPwdFeedback({ suggestions = [], warnings = [] }) {
            this.pwdFeedback.suggestions = suggestions;
            this.pwdFeedback.warnings = warnings;
        },

        setPwdFocusClass(isHighlight = true) {
            const passwordEl = this.$refs.passwordField.$el;
            passwordEl.classList.toggle('focused', isHighlight);
        },

        /**
         * Performs bulk validation and, if passed, it updates the app's state
         */
        onSubmit() {
            let fieldName;

            const valid = true;

            if (!valid) return;

            this.loading = true;

            // Redirects to original page requested.
            this.$store
                .dispatch('register', this.form)
                .then(() => {
                    this.isConfirm = true;
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.isPreventGlobalError = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

::v-deep .Password__strength-meter {
    margin-bottom: 2.1rem;
    background: $gray-500;
    transition: background 0.3s ease;

    &:before,
    &:after {
        border: none;
    }
    &:after {
        content: 'Password strength';
        display: inline;
        position: static;
        font-size: 0.8rem;
    }

    .focused & {
        background: $gray-300;
    }

    .Password__strength-meter--fill {
        position: static;
    }

    .Password__strength-meter--fill:after {
        position: absolute;
        top: 0.5rem;
        right: 0;
        padding: 0 0.2rem;
        font-size: 0.7em;
        text-transform: uppercase;
        background: inherit;
    }

    &:after,
    .Password__strength-meter--fill:after {
        border-radius: 0.25rem;
    }

    [data-score='4'] {
        background: $success;

        &:after {
            content: 'Strong';
        }
    }
    [data-score='3'] {
        background: lighten($success, 15%);

        &:after {
            content: 'Good';
        }
    }
    [data-score='2'] {
        background: darken($warning, 5%);

        &:after {
            content: 'Fair';
        }
    }
    [data-score='1'] {
        background: lighten($danger, 15%);

        &:after {
            content: 'Weak';
        }
    }
    [data-score='0'] {
        background: $danger;

        &:after {
            content: 'Too short';
        }
    }
}

::v-deep .btn-clean:hover {
    color: $link-hover-color;
}

::v-deep .custom-checkbox {
    margin-right: 0;
}

::v-deep .custom-control-label {
    font-size: 0.9rem;
}
</style>
